<template>
  <component :is="tag" class="objectid" :title="title" @click.prevent="copy">
    <slot>ID: {{ value }}</slot>
  </component>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'objectid',
  props: {
    value: {},
    tag: {
      type: String,
      default: 'span',
    },
    title: {
      type: String,
      default: 'Copy',
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async copy() {
      await this.$copyText(this.value);
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Copied',
        type: 'success',
        color: 'success',
      });
    },
  },
};
</script>

<style lang="scss">
.objectid {
  border: 1px solid var(--v-primary-base);
  padding: 0.1rem 0.2rem;
  cursor: pointer;
  font-family: monospace;
}
</style>
