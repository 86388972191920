<template>
  <div>
    <div>
      Status:
      <v-chip small :style="style" style="text-transform: capitalize">
        {{ [status, substatus].filter(x => x).join(', ') }}
      </v-chip>
      <v-chip small :style="style" class="mx-1" v-if="error">{{ error }}</v-chip>
    </div>
  </div>
</template>

<script>
import { IChatapiGateway } from '@/api/gw';

export default {
  name: 'ChatapiListTile',
  props: {
    data: { type: IChatapiGateway, required: true },
  },
  computed: {
    status() {
      return this.data.status?.accountStatus;
    },
    substatus() {
      if (this.data.status?.statusData?.substatus === ('battery_low_1' || 'battery_low_2')) {
        return 'Low Battery';
      }
      return this.data.status?.statusData?.substatus;
    },
    error() {
      return this.data.hasWarning ? this.data.status?.statusData?.title : null;
    },
    style() {
      if (this.data.hasWarning) {
        return { color: 'white', 'font-weight': 'bold', 'background-color': 'red' };
      } else {
        if (this.status === 'authenticated') {
          return { color: 'green' };
        } else {
          return { color: 'white', 'font-weight': 'bold', 'background-color': 'rgb(193,155,78)' };
        }
      }
    },
  },
};
</script>
