<template>
  <div></div>
</template>

<script>
import { ITelegramGateway } from '@/api/gw';

export default {
  name: 'TelegramListTile',
  props: {
    data: { type: ITelegramGateway, required: true },
  },
};
</script>
