<template>
  <SystemConfig />
</template>

<script>
import SystemConfig from '@/components/SystemConfig';

export default {
  name: 'SystemConfigPage',
  components: {
    SystemConfig,
  },
};
</script>
