<template>
  <v-container fluid class="pa-0"> loginAccountDetails </v-container>
</template>

<script>
export default {
  name: 'loginAccountDetails',
};
</script>

<style scoped></style>
