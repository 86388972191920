<template>
  <v-card height="100%" min-height="150">
    <v-card-title>
      <span class="mainTitle" style="font-size: 20px">
        {{ content.label }}
      </span>
    </v-card-title>
    <v-card-text>
      <p>
        <span class="itemFieldName"> Blocked : </span>
        <span class="textLabel">
          {{ content.blocked || false | transBoolean }}
        </span>
      </p>
      <p>
        <span class="itemFieldName">Need Approval : </span>
        <span class="textLabel">
          {{ content.needApproval || false | transBoolean }}
        </span>
      </p>
      <p>
        <span class="itemFieldName"> Work Time Period : </span>
        <span class="textLabel" v-if="content.workTimePeriod && content.workTimePeriod.timezone">
          {{ content.workTimePeriod.timezone | translateTimeZone('text') }}</span
        >
        <span class="textLabel" v-else> Not Restricted</span>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: 'TeamCard',
  props: {
    content: {
      default: () => ({}),
    },
  },
  computed: {
    timezone: {
      get() {
        if (this.content.workTimePeriod.timezone >= 0) {
          return `+${this.content.workTimePeriod.timezone / 60}`;
        } else if (this.content.workTimePeriod.timezone == null) {
          return '+8';
        } else return this.content.workTimePeriod.timezone / 60;
      },
    },
    startTime: {
      get() {
        if (this.content.workTimePeriod.start == null) return null;
        return moment(this.content.workTimePeriod.start).format('HH:mm');
      },
    },
    endTime: {
      get() {
        if (this.content.workTimePeriod.end == null) return null;
        return moment(this.content.workTimePeriod.end).format('HH:mm');
      },
    },
  },
};
</script>

<style scoped></style>
