<template>
  <ListView
    title="Teams"
    @reload="reload"
    @create="actionCreate"
    :search.sync="search"
    :data="data"
    :page.sync="page"
  >
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title>
              <span class="mainTitle" style="font-size: 20px"> Create New Team </span>
              <v-spacer />
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col>
                    <label-with-text-field
                      item-field-name="Team Name"
                      v-model="newTeam.label"
                      :required="true"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="dialog = false">Close</v-btn>
              <v-btn color="primary" @click="createNewTeam()">Create</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template v-slot:card="{ cardContent }">
      <Card :content="cardContent" class="clickable" @click.native="actionDetails(cardContent)" />
    </template>
  </ListView>
</template>

<script>
import Card from '@/components/Team/TeamCard';
import listViewPageMixin from '@/services/listViewPageMixin';
import { crudCreate } from '@/api/crud';
import api from '@/api';
import { getOid } from '@/services/utils';
import LabelWithTextField from '../../components/customFormLabel/labelWithTextField';

export default {
  name: 'Teams',
  mixins: [listViewPageMixin],
  components: { LabelWithTextField, Card },
  data() {
    return {
      dialog: false,
      newTeam: {},
    };
  },
  computed: {
    restfulURL: () => 'im/admin/teams',
  },
  methods: {
    actionCreate() {
      this.dialog = true;
    },
    async createNewTeam() {
      if (!this.newTeam.label) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: "Team name can't empty",
        });
        return;
      }
      try {
        const temp = await crudCreate(api, 'im/admin/teams', this.newTeam);
        this.$router.push({ name: 'Team', params: { id: getOid(temp) } }).catch(() => {});
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Created',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        let msg = err.message;
        if (err.code === 11000) {
          if ('label' in err.keyValue) {
            msg = 'Duplicate Team Label';
          }
        }
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg,
        });
      }
    },
  },
};
</script>
