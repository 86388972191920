<template>
  <v-container>
    <v-container class="mtb-3">
      <h2 class="mainTitle">System Config</h2>
    </v-container>
    <v-card outlined class="mb-3">
      <SectionChatGateways />
    </v-card>
  </v-container>
</template>

<script>
import SectionChatGateways from '@/components/SystemConfig/SectionChatGateways';

export default {
  name: 'SystemConfig',
  components: {
    SectionChatGateways,
  },
};
</script>
